import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"

const PortfolioIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const gridOrder = [
    { name: "Craft" },
    { name: "Design", displayName: "Design & Interiors" },
    { name: "Gardening" },
    { name: "Food" },
    { name: "Drink" },
    { name: "History" },
    { name: "Events" },
    { name: "Tech" },
    { name: "Editorial" },
  ]

  gridOrder.forEach((value, index) => {
    gridOrder[index].image = data.allFile.edges.findIndex(({ node }) =>
      node.name.toLowerCase().includes(value.name.toLowerCase())
    )
  })

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Portfolio" />
      <div style={{ margin: `auto`, maxWidth: rhythm(44) }}>
        <article>
          <header>
            <div
              style={{
                marginBottom: rhythm(1 / 4),
                textAlign: `center`,
              }}
              className="portfolio-grid"
            >
              {gridOrder.map((value, index) => {
                return (
                  <div className="portfolio-grid__block" key={index}>
                    <Link
                      to={value.name.toLowerCase()}
                      className="portfolio-grid__link"
                    >
                      <div className="portfolio-grid__name">
                        {value.displayName || value.name}
                      </div>
                      {value.image !== -1 && (
                        <Img
                          key={index}
                          fluid={
                            data.allFile.edges[value.image].node.childImageSharp
                              .fluid
                          }
                        />
                      )}
                    </Link>
                  </div>
                )
              })}
            </div>
          </header>
        </article>
      </div>
    </Layout>
  )
}

export default PortfolioIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
        absolutePath: { regex: "/portfolio/" }
      }
    ) {
      totalCount
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 460, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  }
`
